import * as React from "react";
import { useState } from "react";

import { Button, Hero, useBarcodeScanner } from "@literati/dj-react";

import useSearch from "./useSearch";
import { BoxReturn } from "./useSearch/types";
import { relativeTimeFromDates } from "utils/RelativeTime";
import useStation from "hooks/useStation";

import "./search.css";

export default function SearchView() {
  return (
    <>
      <Hero>
        <h1>Returns Intake</h1>
        <p>Scan or enter a box&apos;s barcode to start a return.</p>
      </Hero>
      <div className="search-container">
        <div>
          <StationTotes />
        </div>
        <div className="search-content">
          <div>
            <SearchInput />
            <Errors />
            <BoxHistory />
          </div>
        </div>
      </div>
    </>
  );
}

function SearchInput() {
  const { barcodeSearch } = useSearch();

  const [barcode, setBarcode] = useState("");

  useBarcodeScanner({
    types: ["PACKAGE"],
    onScan: (barcode) => barcodeSearch(barcode),
  });

  function go() {
    barcodeSearch(barcode);
  }

  return (
    <>
      <div>
        <h2>Box Search</h2>
      </div>
      <div className="form">
        <input type="text" onChange={(e) => setBarcode(e.target.value)} />
        <Button onClick={() => go()}>Select</Button>
      </div>
    </>
  );
}

function Errors() {
  const { errors } = useSearch();
  if (!errors.length) return null;

  return (
    <div>
      Errors:
      <ul>
        {errors.map((error) => (
          <li key={error}>{error}</li>
        ))}
      </ul>
    </div>
  );
}

function BoxHistory() {
  const { history } = useSearch();
  if (!history.length) return null;

  return (
    <>
      <h2>Box History</h2>
      {history.map((boxReturn) => (
        <BoxHistoryItem key={boxReturn.boxShipmentId} boxReturn={boxReturn} />
      ))}
    </>
  );
}

interface BoxHistoryItemProps {
  boxReturn: BoxReturn;
}
function BoxHistoryItem(props: BoxHistoryItemProps) {
  const { boxReturn } = props;
  const { intakeStartAt, stage, boxShipmentId } = boxReturn;
  const unclosed = stage === "intake";

  const { startReturn } = useSearch();

  function selectBox() {
    startReturn(boxShipmentId);
  }

  return (
    <div className={`history-item ${unclosed && "error"}`} onClick={selectBox}>
      <div>Box ID: {boxShipmentId}</div>
      <div>Started: {relativeTimeFromDates(intakeStartAt)}</div>
      {unclosed && (
        <div>
          <b>Error: Needs to be closed</b>
        </div>
      )}
    </div>
  );
}

function StationTotes() {
  const { totes } = useStation();
  const toteSlots = Object.keys(totes || {});

  // Sort tote slots.
  // Since totes have names like RESTOCK-2 and RESTOCK-10, we need to sort them by their numeric value.
  toteSlots.sort((a, b) => {
    const aNum = parseInt(a.split("-")[1]);
    const bNum = parseInt(b.split("-")[1]);
    return aNum - bNum;
  });

  return (
    <div className="tote-list">
      <h2>My Totes</h2>
      {!toteSlots.length && <div>No totes assigned</div>}
      {toteSlots.map((toteSlot) => (
        <div key={toteSlot}>
          <div>
            <b>{toteSlot.toUpperCase()}</b>: {totes[toteSlot]}
          </div>
        </div>
      ))}
    </div>
  );
}
